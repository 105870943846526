<template>
	<v-col cols="12" class="d-flex flex-column align-self-stretch pa-0">
		<v-card flat tile>
			<v-row class="pa-7 pb-3 justify-space-between align-center">
				<v-col class="pa-0" sm="12" md="9" lg="9" xl="9" cols="9">
					<h2>Users - {{ users.length }} Total</h2>
				</v-col>

				<v-col class="align-self-center pa-0" sm="12" md="3" lg="3" xl="3" cols="3">
					<v-text-field
						v-model="search" 
						label="Search User" 
						prepend-inner-icon="mdi-magnify"
						clearable
					></v-text-field>
				</v-col>
			</v-row>

			<v-data-table 
				fixed-header
				height="700px"
				:loading="usersLoading"
				loading-text="Loading Users... Please wait"
				:search="search"
				:headers="headers"
				:items="users"
				:items-per-page="100"
			>
				<template v-slot:top>
					<v-dialog v-model="dialogUserEdit" max-width="500px">
						<v-card>
							<v-card-title>
								<span class="text-h5">Edit User</span>
							</v-card-title>

							<v-card-text>
								<v-container>
									<v-row>
										<v-col cols="12" sm="6" md="4">
											{{ editedItem.user }}
										</v-col>
										<v-col cols="12" sm="6" md="4" v-for="streamer in streamers" :key="streamer.nickname">
											<v-text-field 
												type="number"
												v-model="editedItem[streamer.twitch_id]" 
												:label="streamer.shorthand + ' points'"
											></v-text-field>
										</v-col>
									</v-row>
								</v-container>
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn 
									text 
									color="blue darken-1" 
									:loading="loadingBtn" 
									:disabled="loadingBtn" 
									@click="updateUsers(editedItem)"
								>Save</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>

					<v-dialog v-model="dialogBanUser" max-width="450px">
						<v-card>
							<v-card-title>
								<v-icon class="mr-2">mdi-alert</v-icon>
								{{ editedItem.user }}
							</v-card-title>

							<v-card-text>
								Are you sure you want to ban this user?
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="secondary" @click="dialogBanUser = false">Cancel</v-btn>
								<v-btn color="error" :loading="loadingBanBtn" :disabled="loadingBanBtn" @click="BanUser(editedItem)">Ban</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog>

					<v-dialog v-model="dialogUnbanUser" max-width="450px">
						<v-card>
							<v-card-title>
								<v-icon class="mr-2">mdi-alert</v-icon>
								{{ editedItem.user }}
							</v-card-title>

							<v-card-text>
								Activate the user again?
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="secondary" @click="dialogUnbanUser = false">Cancel</v-btn>
								<v-btn color="error" :loading="loadingBanBtn" :disabled="loadingBanBtn" @click="BanUser(editedItem)">Unban</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</template>

				<template v-slot:item.actions="{ item }">
					<v-btn fab small text color="primary" @click="editItem(item)">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
					
					<v-btn :disabled="item.banned" color="error" fab small text @click="banUser(item)">
						<v-icon>mdi-account-cancel</v-icon>
					</v-btn>

					<v-btn :disabled="!item.banned" color="primary" fab small text @click="unbanUser(item)">
						<v-icon>mdi-account-reactivate</v-icon>
					</v-btn>
				</template>

			</v-data-table>
		</v-card>
	</v-col>
</template>

<script>
export default {
	data() {
		return {
			loadingBtn: false,
			loadingBanBtn: false,
			usersLoading: true,
			dialogUserEdit: false,
			dialogBanUser: false,
			dialogUnbanUser: false,
			headers: [
				{
					text: 'User',
					sortable: false,
					filterable: true,
					value: 'user'
				},
				{
					text: 'Register date',
					sortable: false,
					filterable: false,
					value: 'register_date'
				},
				{
					text: 'Redeems',
					filterable: false,
					sortable: true,
					value: 'redeems'
				},
			],
			users: [],
			streamers: [],
			search: '',
			editedIndex: -1,
			editedItem: {
				user: ''
			},
		}
	},

	async mounted() {
		try {
			const request = await fetch(`${this.$store.state.base_url}/enrolled/streamers`, {
				method: 'GET',
				headers: {
					"Authorization" : this.$cookies.get('guardian_key_session'),
				}
			});
			
			const res = await request.json();

			if (request.ok) {
				if (res.streamers.length >= 0) {
					this.streamers = res.streamers;

					this.streamers.forEach(streamer => {
						this.headers.push({
							text: streamer.shorthand + ' points',
							filterable: false,
							sortable: true,
							value: `${streamer.twitch_id}`,
						});

						this.headers.push({
							text: streamer.shorthand + ' chats',
							filterable: false,
							sortable: true,
							value: `${streamer.twitch_id}_chats`,
						});

						this.headers.push({
							text: streamer.shorthand + ' watch(hrs)',
							filterable: false,
							sortable: true,
							value: `${streamer.twitch_id}_watchtime`,
						});
					});

					this.headers.push({ 
						text: 'Actions', 
						value: 'actions', 
						sortable: false
					});

					await this.getUsers();
				}
			}
			else {
				if (res.error) {
					if (this.$store.state.error_types.includes(res.error)) {
						this.sessionExpiredHandler(res);
					}
					else {
						throw res.error;
					}
				}
			}
		} 
		catch (error) {
			this.$notify({
				group: 'main',
				type: 'error',
				title: this.$store.state.app_name,
				text: error.message
			});
		}
	},

	destroyed() {
		this.users = [];
		this.search = '';
	},

	methods: {
		editItem (item) {
			this.editedIndex = this.users.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogUserEdit = true;
		},

		banUser(item) {
			this.editedIndex = this.users.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogBanUser = true;
		},

		unbanUser(item) {
			this.editedIndex = this.users.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogUnbanUser = true;
		},

		getUsers: async function () {
			try {
				const request = await fetch(this.$store.state.base_url + '/channel/users', {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});
				const res = await request.json();

				if (request.ok && request.status === 200) {

					if (res.users) {
						this.users.length = 0;
						
						res.users.forEach((user) => {
							let u = {
								user: user.nickname,
								register_date: user.registration_date,
								redeems: user.redeems.length,
								banned: user.banned
							};

							user.points.forEach(points_shop => {
								u[points_shop.id] = points_shop.points;
							});

							user.chats.forEach(stream_chat => {
								u[stream_chat.id + '_chats'] = stream_chat.messages;
							});

							user.watchtime.forEach(stream_watchtime => {
								u[stream_watchtime.id + '_watchtime'] = Math.floor((stream_watchtime.time / 1000) / 3600);
							});

							this.users.push(u);
						});
					}
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message
				});
			}
			finally {
				this.usersLoading = false;
			}
		},

		async updateUsers (item) {
			try {
				this.loadingBtn = true;

				const request = await fetch(this.$store.state.base_url + '/user/update', {
					method: 'PUT',
					body: JSON.stringify({
						item
					}),
					headers: {
						"Content-type" : "application/json; charset=UTF-8",
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});
				const res = await request.json();

				if (request.ok) {
					if (res.isUserUpdated) {
						await this.getUsers();

						this.dialogUserEdit = false;

						this.$notify({
							group: 'main',
							type: 'success',
							title: this.$store.state.app_name,
							text: 'User updated successfully!'
						});
					}
					else if (res.error) {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: res.error
						});
					}
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error
				});
			}
			finally {
				this.loadingBtn = false;
			}
		},

		async BanUser (user) {
			try {
				this.loadingBanBtn = true;

				const request = await fetch(this.$store.state.base_url + '/user/toggle_ban', {
					method: 'PUT',
					body: JSON.stringify({
						user: user.user
					}),
					headers: {
						"Content-type" : "application/json; charset=UTF-8",
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});

				const res = await request.json();

				if (request.ok) {
					if (res.isUserBanned) {
						await this.getUsers();

						this.$notify({
							group: 'main',
							type: 'success',
							title: this.$store.state.app_name,
							text: 'User banned successfully!'
						});
						this.dialogBanUser = false;
					}
					else if (res.isUserUnbanned) {
						await this.getUsers();

						this.$notify({
							group: 'main',
							type: 'success',
							title: this.$store.state.app_name,
							text: 'User unbanned successfully!'
						});
						this.dialogUnbanUser = false;
					}
					else if (res.error) {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: res.error
						});
					}
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error
				});
			}
			finally {
				this.loadingBanBtn = false;
			}
		}
	},
}
</script>